export const SegmentosData = [
  'Varejistas',
  'Energia',
  'Bancos Digitais',
  'Financeiras',
  'Montadoras',
  'Concessionárias',
  'Centros Médicos e de Exames',
  'Academias',
  'Farmácias',
  'Rastreamento',
  'Operadores de Tv',
  'Agências de Viagens',
  'Pet Shops',
  'Clínicas Veterinárias',
].map((s, i) => ({
  label: s,
  id: i,
}));
