/* eslint-disable no-alert */
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import Axios from 'axios';
import Loader from 'react-loader-spinner';
import Container from '../../atoms/Container';
import { StyledHomeContato } from './HomeContato.styles';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const HomeContato: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      company: '',
      email: '',
      message: '',
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const form = new FormData();
        form.append('Name', values.name);
        form.append('Company', values.company);
        form.append('Email', values.email);
        form.append('Message', values.message);

        await Axios.post(`${process.env.REACT_APP_API_URL}/email/contact`, form);
        alert('Solicitação de contato enviada com sucesso!');
      } catch (error) {
        alert('Oops, ocorreu um erro inesperado ao registar sua solicitação de contato.');
      } finally {
        setLoading(false);
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(2, 'Mínimo de 2 caracteres.')
        .max(50, 'Máximo de 50 caracteres.')
        .required('Nome é obrigatório.'),
      company: Yup.string()
        .min(5, 'Mínimo de 5 caracteres.')
        .max(100, 'Máximo de 100 caracteres.')
        .required('Empresa é obrigatório.'),
      email: Yup.string()
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          'E-mail inválido',
        )
        .required('E-mail é obrigarório.'),
      message: Yup.string().required('Mensagem é obrigatória'),
    }),
  });

  return (
    <StyledHomeContato id="contato">
      <Container className="home-contato-container">
        <h2>Contato</h2>
        <h3>
          Quer saber mais sobre nossa plataforma e como podemos ajudá-lo a vender seguros e
          assistências? Envie-nos uma mensagem e entraremos em contato o mais rápido possível com
          informaçõs detalhadas.
        </h3>
        <form onSubmit={formik.handleSubmit}>
          <input
            name="name"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.name}
            placeholder="Nome"
            maxLength={50}
          />
          {formik.errors.name && formik.touched.name && <p>{formik.errors.name}</p>}
          <input
            name="company"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.company}
            placeholder="Empresa"
            maxLength={100}
          />
          {formik.errors.company && formik.touched.company && <p>{formik.errors.company}</p>}
          <input
            name="email"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.email}
            placeholder="E-mail"
            maxLength={50}
          />
          {formik.errors.email && formik.touched.email && <p>{formik.errors.email}</p>}
          <textarea
            name="message"
            onChange={formik.handleChange}
            value={formik.values.message}
            placeholder="Mensagem"
            maxLength={240}
          />
          {formik.errors.message && formik.touched.message && <p>{formik.errors.message}</p>}
          <button type="submit" disabled={loading}>
            {loading ? <Loader type="Oval" color="#FFF" height={30} width={30} /> : 'Enviar'}
          </button>
        </form>
      </Container>
    </StyledHomeContato>
  );
};

export default HomeContato;
