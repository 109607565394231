import styled from 'styled-components';

export const StyledHomeQuemSomos = styled.section`
  padding: 32px 0;

  .home-quem-somos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    > h2 {
      color: #2ac67f;
      font-size: ${({ theme }) => `${theme.font.title}px`};
      line-height: ${({ theme }) => `${theme.font.title + 2}px`};
      margin-bottom: 32px;
      font-weight: normal;

      @media (min-width: 992px) {
        font-weight: bold;
      }
    }

    > h3 {
      font-size: ${({ theme }) => `${theme.font.paragraph}px`};
      line-height: ${({ theme }) => `${theme.font.paragraph + 2}px`};
      color: #595959;
      font-weight: normal;
      max-width: 327px;
      margin-bottom: 32px;

      b {
        font-weight: 500;
      }

      @media (min-width: 992px) {
        max-width: 650px;
        margin-bottom: 42px;
      }
    }
  }
`;

export const StyledQuemSomosEmpresas = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: flex-start;
    max-width: 1090px;
  }

  article {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    flex: 1;

    > h2 {
      color: #2ac67f;
      font-size: 28px;
      line-height: 30px;
      margin-bottom: 0px;
      font-weight: bold;
      max-width: 340px;

      @media (min-width: 992px) {
        font-size: 24px;
        line-height: 26px;
        font-weight: bold;
        max-width: 306px;
      }
    }

    > h3 {
      font-size: 18px;
      line-height: 20px;
      color: #595959;
      font-weight: normal;
      max-width: 307px;
      margin-bottom: 0;
      @media (min-width: 992px) {
        font-size: 16px;
        line-height: 18px;
        max-width: 234px;
      }
    }

    .empresa-logo {
      margin-bottom: 32px;
    }
  }
`;
