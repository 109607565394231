import React from 'react';
import { createGlobalStyle } from 'styled-components';

export const ResetStyles = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-family: inherit;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
`;

export const GlobalStyles = createGlobalStyle`
  * {
    font-family: 'Quicksand', sans-serif;
  }

  html {
    font-size: 16px;
    background-color: #fff;
    scroll-behavior: smooth;
  }
  a {
    text-decoration: none;
  }

  @media(min-width: 1200px){
    body {
      zoom: 1.25
    }
  }
`;

export default function Global() {
  return (
    <>
      <ResetStyles />
      <GlobalStyles />
    </>
  );
}
