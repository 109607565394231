import React from 'react';
import Container from '../../atoms/Container';
import Image from '../../atoms/Image';
import { StyledHomeQuemSomos, StyledQuemSomosEmpresas } from './HomeQuemSomos.styles';

const HomeQuemSomos: React.FC = () => {
  return (
    <StyledHomeQuemSomos id="quem-somos">
      <Container className="home-quem-somos-container">
        <h2>Quem somos</h2>
        <h3>
          A doHub traz <b>inovação, tecnologia e experiência</b> para conectar produtos de seguros e
          serviços com o cliente final. Geramos <b>novas receitas</b> para os parceiros oferecendo
          ofertas por vendas digitais, montadas pelos Corretores de Seguros com as Seguradoras e
          Empresas de Serviços.
        </h3>

        <StyledQuemSomosEmpresas>
          <article>
            <Image
              wrapper={{
                className: 'empresa-logo',
              }}
              width={150}
              image={{
                src: 'assets/img/img1varejista-min.png',
              }}
            />
            <h2>Varejista, Atacadista, Empresa Financeira:</h2>
            <h3>
              integramos você às melhores Seguradoras, Empresas de Assistência, Capitalização,
              Financeira e outras, com total suporte nas negociações e na criação de produtos.
            </h3>
          </article>

          <article>
            <Image
              wrapper={{
                className: 'empresa-logo',
              }}
              width={150}
              image={{
                src: 'assets/img/img2-corretor-min.png',
              }}
            />
            <h2>Corretor de Seguros:</h2>
            <h3>
              integramos você e seus parceiros com as melhores Seguradoras e Empresas de
              Assistência, Capitalização. Defina seus produtos e nós conectamos todos os envolvidos.
            </h3>
          </article>

          <article>
            <Image
              wrapper={{
                className: 'empresa-logo',
              }}
              width={150}
              image={{
                src: 'assets/img/img3-corretor-min.png',
              }}
            />
            <h2>Empresas de Seguro, Assistência, Rastreamento e Capitalização Financeira:</h2>
            <h3>
              nós integramos e disponibilizamos os seus produtos para nossos parceiros e corretores.
            </h3>
          </article>
        </StyledQuemSomosEmpresas>
      </Container>
    </StyledHomeQuemSomos>
  );
};

export default HomeQuemSomos;
