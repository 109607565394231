import React from 'react';
import Button from '../../atoms/Button';
import Container from '../../atoms/Container';
import { StyledHomeSobre, StyledHeaderBackground } from './HomeSobre.styles';

const HomeSobre = () => {
  return (
    <StyledHomeSobre id="section-sobre">
      <Container className="home-sobre-container">
        <h1>Somos uma plataforma digital criada para conectar o mercado segurador</h1>
        <h2>
          Conectamos produtos de seguros, assistências e capitalização com seu cliente, de forma
          100% digital.
        </h2>
        <div className="btns-row">
          <Button colorset="variant-1" href="#contato">
            Saber Mais
          </Button>
          <Button colorset="variant-2" href="#solucao">
            Ver serviços
          </Button>
        </div>
      </Container>
      <StyledHeaderBackground />
    </StyledHomeSobre>
  );
};

export default HomeSobre;
