import React from 'react';
import { useLayout } from '../../../hooks/useLayout';
import Container from '../../atoms/Container';
import Image from '../../atoms/Image';
import { StyledFooter } from './Footer.styles';

const Footer: React.FC = () => {
  const { isMobile } = useLayout();

  return (
    <StyledFooter>
      <Container className="footer-container">
        <div className="footer-content left desktop-only">
          <a href="#">Home</a>
          <a href="#quem-somos">Quem somos</a>
          <a href="#plataforma">Plataforma</a>
          <a href="#solucao">Solução</a>
          <a href="#servicos">Serviços Conectados</a>
          <a href="#numeros">Números</a>
          <a href="#contato">Contato</a>
        </div>

        <div className="footer-content fix">
          <Image
            width={isMobile ? 140 : 200}
            image={{
              src: 'assets/img/logo_footer.png',
            }}
          />
          <p className="sub">powered by GTI Solution</p>
          <br />
          <br />
          <p>@2021 doHub</p>
          <p className="sub">Todos os direitos reservados</p>
        </div>

        <div className="footer-content right">
          <p>doHub nas redes</p>
          <div className="redes">
            <a href="https://www.facebook.com/dohuboficial" target="_blank" rel="noreferrer">
              <Image
                width={32}
                image={{
                  src: 'assets/img/facebook.png',
                }}
              />
            </a>
            <a href="https://www.instagram.com/_dohub/" target="_blank" rel="noreferrer">
              <Image
                width={32}
                image={{
                  src: 'assets/img/insta.png',
                }}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/dohuboficial/"
              target="_blank"
              rel="noreferrer">
              <Image
                width={32}
                image={{
                  src: 'assets/img/linkedin.png',
                }}
              />
            </a>
          </div>
          <p>
            Av. Sagitário, 138 – Torre London <br /> 6º Andar – Sala 604 - Alphaville Conde II
            <br /> Edifício Alpha Square, Barueri – SP <br /> CEP: 06473-073
          </p>
          <a href="mailto:contato@dohub.com.br">contato@dohub.com.br</a>
          <p>+ 55 11 4208-2143</p>
        </div>

        <div className="footer-content mobile-only">
          <p>@2021 doHub</p>
          <p className="sub">Todos os direitos reservados</p>
        </div>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
