/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { StyledComponentProps } from 'styled-components';
import { StyledContainer, StyledContainerProps } from './Container.styles';

type ContainerProps = StyledComponentProps<'div', any, StyledContainerProps, never>;

const Container: React.FC<ContainerProps> = ({ ...props }) => {
  return <StyledContainer {...props} />;
};

export default Container;
