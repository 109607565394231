import React from 'react';
import HomeCanais from '../../molecules/HomeCanais/HomeCanais';
import HomeContato from '../../molecules/HomeContato';
import HomeNumeros from '../../molecules/HomeNumeros';
import HomePlataforma from '../../molecules/HomePlataforma';
import HomeProdutos from '../../molecules/HomeProdutos';
import HomeQuemSomos from '../../molecules/HomeQuemSomos';
import HomeSegmentos from '../../molecules/HomeSegmentos';
import HomeSobre from '../../molecules/HomeSobre';
import Footer from '../../organisms/Footer';
import Header from '../../organisms/Header';

const Home: React.FC = () => {
  return (
    <>
      <Header />
      <HomeSobre />
      <HomeQuemSomos />
      <HomePlataforma />
      <HomeSegmentos />
      <HomeCanais />
      <HomeProdutos />
      <HomeNumeros />
      <HomeContato />
      <Footer />
    </>
  );
};

export default Home;
