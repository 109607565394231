import styled from 'styled-components';

export const StyledHomeProdutos = styled.section`
  padding-top: 32px;

  .home-produtos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    > h2 {
      text-align: center;
      color: #5ff772;
      font-size: ${({ theme }) => `${theme.font.title}px`};
      line-height: ${({ theme }) => `${theme.font.title + 2}px`};

      font-weight: 500;
      margin-bottom: 16px;

      @media (min-width: 992px) {
        font-weight: bold;
      }
    }

    > h3 {
      text-align: center;
      color: #595959;
      font-size: ${({ theme }) => `${theme.font.paragraph}px`};
      line-height: ${({ theme }) => `${theme.font.paragraph + 2}px`};
      max-width: 285px;
      font-weight: 500;
      margin-bottom: 32px;
      @media (min-width: 992px) {
        max-width: 630px;
      }
    }
  }
`;

export const StyledProdutosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 288px;

  @media (min-width: 992px) {
    max-width: 728px;
  }

  .produto {
    position: relative;
    margin-right: 8px;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 8px;

    :nth-child(even) {
      margin-right: 0;
    }

    @media (min-width: 992px) {
      margin-right: 16px;
      margin-bottom: 16px;

      :nth-child(even) {
        margin-right: 16px;
      }
      :nth-child(4),
      :nth-child(8) {
        margin-right: 0;
      }
    }
  }

  .produtos-info {
    position: absolute;
    z-index: 1;
    top: 16px;
    left: 16px;

    h2,
    h3,
    h4,
    p {
      color: inherit;
      text-align: left;
    }

    h3 {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 16px;
      max-width: 95px;

      @media (min-width: 992px) {
        font-size: 16px;
        line-height: 18px;
        max-width: 110px;
      }
    }

    p {
      font-size: 12px;
      line-height: 14px;

      @media (min-width: 992px) {
        font-size: 14px;
        line-height: 16 px;
      }
    }
  }
`;
