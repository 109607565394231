import styled from 'styled-components';

export const StyledHomeSobre = styled.section`
  position: static;
  padding-bottom: 76px;
  @media (min-width: 992px) {
    padding-bottom: 250px;
  }

  .home-sobre-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
      font-size: 28px;
      line-height: 30px;
      color: #5ff772;
      font-weight: normal;
      margin-bottom: 32px;
      max-width: 320px;
      @media (min-width: 992px) {
        font-size: 38px;
        line-height: 40px;
        max-width: 750px;
        font-weight: bold;
      }
    }

    h2 {
      font-size: 18px;
      line-height: 20px;
      color: #fff;
      font-weight: normal;
      margin-bottom: 32px;
      max-width: 300px;
      @media (min-width: 992px) {
        font-size: ${({ theme }) => `${theme.font.subtitle}px`};
        line-height: ${({ theme }) => `${theme.font.subtitle + 2}px`};
        max-width: 550px;
        margin-bottom: 48px;
      }
    }

    .btns-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > :first-child {
        margin-bottom: 16px;
      }

      @media (min-width: 992px) {
        flex-direction: row;

        > :first-child {
          margin-bottom: 0;
          margin-right: 16px;
        }
      }
    }
  }
`;

export const StyledHeaderBackground = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: -1;
  height: 550px;
  background-image: url(assets/img/mobile_header_bg.png);
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: cover;

  .circle-small,
  .circle-big {
    display: none;
  }

  @media (min-width: 992px) {
    background-image: url(assets/img/desk_header_bg.png);
    background-position: 50% -2px;
    max-width: 1437px;
    height: 680px;

    /* .circle-small,
    .circle-big {
      display: block;
    }

    .circle-small {
      position: absolute;
      right: 0;
      top: 60%;
      @media (min-width: 1200px) {
        top: 70%;
        right: 14%;
      }
      @media (min-width: 1440px) {
        right: 20%;
      }
    }

    .circle-big {
      position: absolute;
      left: 0;
      top: 60%;
      @media (min-width: 1200px) {
        top: 40%;
        left: 0;
      }

      @media (min-width: 1440px) {
        left: 7%;
      }
    } */
  }
`;
