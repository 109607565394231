import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import HomePage from '../pages/home';

const PublicRoutes: React.FC = () => {
  const getBasename = () => {
    try {
      const basename = process.env.REACT_APP_HOMEPAGE || '';

      return basename.replace('http://', '').replace('https://', '');
    } catch (error) {
      return '';
    }
  };

  return (
    <BrowserRouter basename={getBasename()}>
      <Switch>
        <Route exact path="/" component={HomePage} />
      </Switch>
    </BrowserRouter>
  );
};

export default PublicRoutes;
