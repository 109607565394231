import React from 'react';
import Container from '../../atoms/Container';
import Image from '../../atoms/Image';
import { StyledHomeNumeros, StyledNumerosBg, StyledNumerosWrapper } from './HomeNumeros.styles';

const HomeNumeros: React.FC = () => {
  return (
    <StyledHomeNumeros id="numeros">
      <Container className="home-numeros-container">
        <h2>Números doHub</h2>

        <StyledNumerosWrapper>
          <div>
            <h4>17</h4>
            <p>Seguradoras</p>
          </div>
          <div>
            <h4>12</h4>
            <p>Assistências</p>
          </div>
          <div className="desk-only">
            <h4>
              +25 <br /> <b>milhões</b>
            </h4>
            <p>Clientes finais</p>
          </div>
          <div>
            <h4>21</h4>
            <p>Produtos</p>
          </div>
          <div>
            <h4>+120</h4>
            <p>Parceiros</p>
          </div>
          <div className="mobile-only">
            <h4>
              +25 <br /> <b>milhões</b>
            </h4>
            <p>Clientes finais</p>
          </div>
        </StyledNumerosWrapper>
      </Container>
      <StyledNumerosBg />
      <Image
        width={70}
        wrapper={{ className: 'circle' }}
        image={{
          src: 'assets/img/circulo-verde-sombra.png',
        }}
      />
    </StyledHomeNumeros>
  );
};

export default HomeNumeros;
