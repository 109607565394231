import styled from 'styled-components';

export const StyledHomeContato = styled.section`
  .home-contato-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    > h2 {
      margin-top: 16px;
      color: #2ac67f;
      font-size: ${({ theme }) => `${theme.font.title}px`};
      line-height: ${({ theme }) => `${theme.font.title + 2}px`};
      margin-bottom: 16px;
      font-weight: normal;
      text-align: center;

      @media (min-width: 992px) {
        font-weight: bold;
        margin-top: 60px;
      }
    }

    > h3 {
      font-size: ${({ theme }) => `${theme.font.paragraph}px`};
      line-height: ${({ theme }) => `${theme.font.paragraph + 2}px`};
      color: #595959;
      font-weight: normal;
      max-width: 327px;
      margin-bottom: 32px;
      text-align: center;

      @media (min-width: 992px) {
        max-width: 640px;
      }
    }

    form {
      width: 100%;
      max-width: 375px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      input,
      textarea {
        width: 100% !important;
        border-radius: 4px;
        border: 1px solid #595959;
        margin-bottom: 8px;
        box-sizing: border-box;
        padding: 4px 8px;
        color: #595959;
        outline: none;

        ::placeholder {
          color: #a5a5a5;
        }
      }

      textarea {
        min-height: 100px;
      }

      button {
        border: none;
        box-shadow: none;
        background: none;
        background-color: #2ac67f;
        color: white;
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        font-size: ${({ theme }) => `${theme.font.paragraph}px`};
        line-height: ${({ theme }) => `${theme.font.paragraph + 2}px`};
        height: 42px;
        width: 150px;
        border-radius: 4px;
        margin-top: 16px;
        cursor: pointer;
        transition: 0.3s ease;

        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
        }

        :hover {
          filter: brightness(150%);
        }
        :disabled:hover {
          filter: none;
          cursor: wait;
        }
      }

      p {
        text-align: left;
        margin-bottom: 8px;
        align-self: flex-start;
        color: red;
        font-size: 14px;
        line-height: 16px;
        :before {
          content: '* ';
        }
      }
    }
  }
`;
