import React from 'react';
import { useLayout } from '../../../hooks/useLayout';
import Container from '../../atoms/Container';
import Image from '../../atoms/Image';
import { StyledCanaisBg, StyledHomeCanais } from './HomeCanais.styles';

const HomeCanais: React.FC = () => {
  const { isMobile } = useLayout();
  return (
    <StyledHomeCanais>
      <Container className="home-canais-container">
        <h2>
          Solução para diversos <b>canais de vendas</b>
        </h2>
        <h3>
          Nossa diversidade de produtos e serviços atende diversos canais de vendas e auxiliam na
          atração e fidelização dos seus clientes.
        </h3>
        <Image
          width={isMobile ? 250 : 380}
          wrapper={{ className: 'circle' }}
          image={{
            src: 'assets/img/canais.png',
          }}
        />
      </Container>
      <StyledCanaisBg />
    </StyledHomeCanais>
  );
};

export default HomeCanais;
