/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React, { HTMLAttributes, DetailedHTMLProps, ImgHTMLAttributes, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { StyledComponentProps } from 'styled-components';
import { StyledImageWrapper } from './Image.styles';

interface ImageProps {
  wrapper?: StyledComponentProps<'div', any, {}, never>;
  image: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
  width?: number;
  height?: number;
  preload?: boolean;
}

const Image: React.FC<ImageProps> = ({ wrapper, image, height, width, preload }) => {
  const styles = useMemo(() => {
    const obj = wrapper?.style || {};

    if (height) {
      obj.height = `${height}px`;
    }

    if (width) {
      obj.width = `${width}px`;
    }

    return obj;
  }, [height, width, wrapper]);

  return (
    <StyledImageWrapper {...wrapper} style={styles}>
      {preload && (
        <Helmet>
          <link rel="preload" href={image.src} as="image" />
        </Helmet>
      )}
      <img {...image} />
    </StyledImageWrapper>
  );
};

export default Image;
