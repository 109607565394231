import styled from 'styled-components';

export const StyledFooter = styled.footer`
  background-color: #0f1c4c;
  margin-top: 80px;
  padding: 20px 0 40px 0;
  @media (min-width: 992px) {
    padding: 60px 0;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media (min-width: 992px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      max-width: 1000px;
    }

    p,
    a {
      color: #fff;
      text-align: center;
      font-size: ${({ theme }) => `${theme.font.paragraph}px`};
      line-height: ${({ theme }) => `${theme.font.paragraph + 2}px`};
      font-weight: 400;
    }

    .sub {
      font-size: ${({ theme }) => `${theme.font.paragraph - 2}px`};
      line-height: ${({ theme }) => `${theme.font.paragraph}px`};
      font-weight: 300;
    }

    .redes {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 16px 0;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        cursor: pointer;

        :last-of-type {
          margin-right: 0;
        }
      }
    }

    .footer-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 32px;

      @media (min-width: 992px) {
        margin-bottom: 0;
        &.right {
          text-align: right;
          align-items: flex-end;
          p {
            text-align: right;
          }
        }
        &.left {
          text-align: left;
          align-items: flex-start;
          p,
          a {
            text-align: left;
            margin-bottom: 2px;
            cursor: pointer;
          }
        }
        &.fix {
          position: relative;
          top: -24px;
        }
      }
    }

    .mobile-only {
      display: flex;

      @media (min-width: 992px) {
        display: none;
      }
    }

    .desktop-only {
      display: none;

      @media (min-width: 992px) {
        display: flex;
      }
    }
  }
`;
