export const PlataformaData = [
  {
    label: 'Tecnologia de <br> ponta',
    icon: 'assets/img/ic_tec.png',
  },
  {
    label: 'Portal de <br> vendas',
    icon: 'assets/img/ic_portal.png',
  },
  {
    label: 'WhatsApp',
    icon: 'assets/img/ic_whats.png',
  },
  {
    label: 'Email <br> marketing',
    icon: 'assets/img/ic_email.png',
  },
  {
    label: 'eCommerce',
    icon: 'assets/img/ic_ecommerce.png',
  },
  {
    label: 'SMS',
    icon: 'assets/img/ic_chat.png',
  },
  {
    label: 'Aplicativos',
    icon: 'assets/img/ic_app.png',
    vertical: true,
  },
  {
    label: 'Redes <br> sociais',
    icon: 'assets/img/ic_redes.png',
  },
  {
    label: "API's",
    icon: 'assets/img/ic_api.png',
    vertical: true,
  },
  {
    label: 'Nuvem',
    icon: 'assets/img/ic_cloud.png',
  },
].map((p, i) => ({
  ...p,
  id: i,
}));
