import styled, { css } from 'styled-components';

const heightCss = css`
  min-height: 1400px;

  @media (min-width: 992px) {
    min-height: 1100px;
  }

  @media (min-width: 1920px) {
    min-height: 1200px;
  }
`;

export const StyledHomePlataforma = styled.section`
  position: relative;
  overflow: hidden;
  ${heightCss}

  .home-plataforma-container {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 100px;

    > h2 {
      text-align: center;
      color: #fff;
      font-size: 34px;
      line-height: 36px;
      margin-bottom: 0px;
      max-width: 340px;
      font-weight: 500;

      @media (min-width: 992px) {
        font-weight: bold;
      }
    }

    .circle {
      margin-top: 240px;

      @media (min-width: 992px) {
        visibility: hidden;
      }
    }

    > h3 {
      text-align: center;
      color: #fff;
      font-size: 28px;
      line-height: 30px;
      padding-top: 32px;
      margin-bottom: 16px;
      max-width: 340px;
      font-weight: 500;
    }

    > h4 {
      text-align: center;
      color: #fff;
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 0px;
      max-width: 340px;
      font-weight: normal;
      margin-bottom: 32px;

      @media (min-width: 992px) {
        max-width: 616px;
      }
    }
  }
`;

export const StyledPlataformaBg = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 0;
  background-image: url(assets/img/bg_plataforma.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0px;
  width: 100%;
  max-width: 1920px;
  transform: translate(-50%, 0);
  ${heightCss}

  @media (min-width: 992px) {
    background-image: url(assets/img/bg_plataforma_desk.png);
  }

  @media (min-width: 1920px) {
    background-size: contain;
  }
`;

export const StyledFerramentasContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 250px;

  @media (min-width: 992px) {
    max-width: 800px;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100px;
    width: 120px;
    box-sizing: border-box;

    @media (min-width: 992px) {
      height: 150px;
      width: 150px;
    }

    .logo {
      background-color: #fff;
      border-radius: 50%;
      padding: 10px;
      box-sizing: border-box;
      width: 50px;
      height: 50px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      &.vertical {
        img {
          height: 100%;
          width: auto;
        }
      }

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    p {
      text-align: center;
      color: #fff;
      padding-top: 8px;
      font-size: 16px;
      line-height: 18px;
    }
  }
`;
