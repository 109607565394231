import styled from 'styled-components';

export const StyledHomeSegmentos = styled.section`
  .home-segmentos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > h2 {
      padding-top: 16px;
      font-size: 28px;
      line-height: 30px;
      color: #0f1c4c;
      font-weight: normal;
      margin-bottom: 32px;
      max-width: 320px;
      text-align: center;
      b {
        font-weight: normal;
      }
      @media (min-width: 992px) {
        font-size: 30px;
        line-height: 32px;
        max-width: 550px;
        font-weight: normal;
        b {
          font-weight: bold;
        }
      }
    }

    > h3 {
      color: #595959;
      text-align: center;
      font-weight: normal;
      font-size: 18px;
      line-height: 20px;
      max-width: 320px;

      @media (min-width: 992px) {
        max-width: 520px;
      }
    }

    .segmentos-container,
    .btn-container {
      max-width: 320px;
      @media (min-width: 992px) {
        max-width: unset;
      }
    }

    .segmentos-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-top: 8px;
      margin-bottom: 16px;

      @media (min-width: 992px) {
        margin-top: 32px;
        margin-bottom: 32px;
        max-width: 520px;
        flex-direction: row;
        align-items: stretch;

        > div:first-of-type {
          margin-right: 16px;
        }
      }
    }

    .segmento {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 8px;

      span {
        width: 16px;
        height: 16px;
        margin-right: 16px;
        background-color: #5ff772;
        border-radius: 50%;
      }

      p {
        color: #595959;
        font-size: 18px;
        line-height: 20px;
      }
    }

    .btn-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;

      @media (min-width: 992px) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      > :first-child {
        margin-bottom: 8px;
        @media (min-width: 992px) {
          margin-bottom: 0;
          margin-right: 16px;
        }
      }
    }
  }
`;
