/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { StyledComponentProps } from 'styled-components';
import { StyledAtomButton, StyledAtomButtonProps } from './Button.styles';

type ButtonProps = StyledComponentProps<'a', any, StyledAtomButtonProps, never>;

const Button: React.FC<ButtonProps> = (props) => {
  return <StyledAtomButton {...props} />;
};

Button.defaultProps = {
  uppercase: true,
};

export default Button;
