import styled from 'styled-components';

export const StyledImageWrapper = styled.div`
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;
