import styled, { css } from 'styled-components';

const bgHeight = 490;

const StyledSize = css`
  height: ${bgHeight}px;

  @media (min-width: 375px) {
    height: ${bgHeight + 60}px;
  }

  @media (min-width: 992px) {
    height: ${bgHeight + 160}px;
  }
`;

export const StyledHomeCanais = styled.section`
  position: relative;
  z-index: 1;
  margin-top: 32px;
  ${StyledSize}
  .home-canais-container {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    > h2 {
      text-align: center;
      color: #5ff772;
      font-size: 24px;
      line-height: 26px;
      margin-top: 60px;
      margin-bottom: 8px;
      max-width: 200px;
      font-weight: 500;
      b {
        font-weight: 500;
      }

      @media (min-width: 992px) {
        max-width: 320px;
        font-size: 30px;
        line-height: 32px;
        margin-top: 80px;
        margin-bottom: 32px;

        b {
          font-weight: bold;
        }
      }
    }

    > h3 {
      text-align: center;
      color: #fff;
      font-size: 14px;
      line-height: 16px;
      max-width: 228px;
      font-weight: 500;
      margin-bottom: 16px;
      @media (min-width: 992px) {
        font-size: 18px;
        line-height: 20px;
        font-weight: 400;
        max-width: 550px;
        margin-bottom: 32px;
      }
    }
  }
`;

export const StyledCanaisBg = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 0;
  background-image: url(assets/img/bg_canais.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0px;
  width: 100%;
  transform: translate(-50%, 0);
  ${StyledSize}
`;
