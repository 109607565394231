import styled, { css } from 'styled-components';

const ButtonColorsetData = {
  'variant-1': css`
    color: #0f1c4c;
    background-color: #5ff772;
    border: none;
  `,
  'variant-2': css`
    color: #5ff772;
    background-color: transparent;
    border: 2px solid #5ff772;
  `,
  'variant-3': css`
    color: #fff;
    background-color: #5ff772;
    border: none;
  `,
  'variant-4': css`
    color: #2ac67f;
    background-color: transparent;
    border: 2px solid #2ac67f;
  `,
  'variant-5': css`
    color: #fff;
    background-color: #f130a9;
    border: none;
  `,
};

type ButtonColorset = 'variant-1' | 'variant-2' | 'variant-3' | 'variant-4' | 'variant-5';

export interface StyledAtomButtonProps {
  uppercase?: boolean;
  colorset?: ButtonColorset;
}

export const StyledAtomButton = styled.a<StyledAtomButtonProps>`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 42px;
  min-width: 150px;
  border-radius: 4px;
  font-weight: bold;
  font-size: ${({ theme }) => `${theme.font?.paragraph}px`};
  box-sizing: border-box;
  padding: 0 16px;
  cursor: pointer;
  transition: 0.3s ease;

  :hover {
    filter: brightness(150%);
  }

  ${(props) =>
    props.colorset &&
    css`
      ${ButtonColorsetData[props.colorset]}
    `}

  ${(props) =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `}

  :visited {
    text-decoration: none;
  }
`;
