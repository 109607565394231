import React from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import Global from './components/atoms/Global';
import PublicRoutes from './router/public.routes';
import { BaseTheme } from './utils/themes';

const App = () => (
  <>
    <Helmet />
    <ThemeProvider theme={BaseTheme}>
      <Global />
      <PublicRoutes />
    </ThemeProvider>
  </>
);

export default App;
