import styled, { css, keyframes } from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 40px 8px;

  @media (min-width: 992px) {
    padding: 40px 0px;
    padding-bottom: 145px;
    max-width: 900px;
    margin: 0 auto;
  }

  .toggle-menu {
    @media (min-width: 992px) {
      display: none;
    }
  }
`;

const NavAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

interface StyledNavigationProps {
  isMenu?: boolean;
  isVisible?: boolean;
}

export const StyledNavigation = styled.nav<StyledNavigationProps>`
  opacity: 0;
  display: none;

  @media (min-width: 992px) {
    display: flex;
    opacity: 1;
  }

  .toggle-menu {
    display: none;
  }

  ${(props) =>
    props.isMenu &&
    props.isVisible &&
    css`
      transition: opacity 0.3s linear;
      opacity: 1;
      display: flex;
      animation: ${NavAnimation} 0.3s linear;
    `}

  ${(props) =>
    props.isMenu &&
    css`
      position: fixed;
      z-index: 99;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #181c4a;
      align-items: flex-start;
      justify-content: center;
      padding: 0;
      margin: 0;
      padding-top: calc(32px + 32px);

      .toggle-menu {
        display: block;
        position: absolute;
        top: 48px;
        left: 24px;
        z-index: 100;
      }
    `}


  ul {
    display: flex;
    flex-direction: ${(props) => (props.isMenu ? 'column' : 'row')};
    align-items: center;
    justify-content: flex-start;

    li {
      margin-right: ${(props) => (props.isMenu ? '0' : '22px')};
      margin-bottom: ${(props) => (props.isMenu ? '16px' : 0)};
      &:last-of-type {
        margin-right: 0;
        margin-bottom: 0;
      }

      > a {
        color: #5ff772;
        font-size: ${({ theme }) => `${theme.font.paragraph}px`};
        line-height: ${({ theme }) => `${theme.font.paragraph + 2}px`};
        cursor: pointer;

        @media (min-width: 992px) {
          font-weight: 500;
          font-size: ${({ theme }) => `${theme.font.paragraph + 2}px`};
          line-height: ${({ theme }) => `${theme.font.paragraph + 4}px`};
        }
      }
    }
  }
`;
