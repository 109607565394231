import React from 'react';
import Button from '../../atoms/Button';
import Container from '../../atoms/Container';
import { SegmentosData } from './HomeSegmentos.data';
import { StyledHomeSegmentos } from './HomeSegmentos.styles';

const HomeSegmentos: React.FC = () => {
  return (
    <StyledHomeSegmentos id="solucao">
      <Container className="home-segmentos-container">
        <h2>
          Solução para <b>vários segmentos</b>
        </h2>
        <h3>
          Atendemos parceiros de diferentes segmentos do mercado, possibilitando que eles ofertem
          uma completa família de produtos aos seus clientes, de acordo com suas necessidades.
        </h3>

        <div className="segmentos-container">
          <div>
            {SegmentosData.slice(0, SegmentosData.length / 2).map((s) => (
              <div className="segmento" key={`segmentos-${s.id}`}>
                <span />
                <p>{s.label}</p>
              </div>
            ))}
          </div>
          <div>
            {SegmentosData.slice(SegmentosData.length / 2).map((s) => (
              <div className="segmento" key={`segmentos-${s.id}`}>
                <span />
                <p>{s.label}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="btn-container">
          <Button colorset="variant-5" href="#servicos">
            Ver produtos
          </Button>
          <Button colorset="variant-4" href="#contato">
            Entrar em contato
          </Button>
        </div>
      </Container>
    </StyledHomeSegmentos>
  );
};

export default HomeSegmentos;
