import { useEffect, useState } from 'react';

interface UseLayoutProps {
  mobileBreakpoint: number;
  debug?: boolean;
}

const initialOptions: UseLayoutProps = {
  mobileBreakpoint: 992,
  debug: true,
};

export function useLayout(options = initialOptions) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window && window.innerWidth < options.mobileBreakpoint);
    };
    window.addEventListener('load', checkMobile, false);
    window.addEventListener('resize', checkMobile, false);

    return () => {
      window.removeEventListener('load', checkMobile);
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  return {
    isMobile,
  };
}
