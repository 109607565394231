import React from 'react';
import { useLayout } from '../../../hooks/useLayout';
import Container from '../../atoms/Container';
import Image from '../../atoms/Image';
import { ProdutosData } from './HomeProdutos.data';
import { StyledHomeProdutos, StyledProdutosWrapper } from './HomeProdutos.styles';

const HomeProdutos: React.FC = () => {
  const { isMobile } = useLayout();

  return (
    <StyledHomeProdutos id="servicos">
      <Container className="home-produtos-container">
        <h2>Produtos Conectados</h2>
        <h3>
          Somos um Hub com uma ampla gama de
          <b> produtos de Seguros e Serviços acoplados e em constante expansão</b>. Conectamos os
          melhores parceiros e serviços do mercado para que sua empresa possa ofertar
          <b> exatamente o que seu cliente precisa</b>, tudo em poucos cliques.
        </h3>

        <StyledProdutosWrapper>
          {ProdutosData.map((p) => (
            <div key={`produtos-data-${p.id}`} className="produto">
              <div
                className="produtos-info"
                style={{
                  color: p.textColor,
                }}>
                <h3>{p.title}</h3>
                {p.labels.map((l) => (
                  <p key={`produtos-label-${p.id}-${l.id}`}>{l.text}</p>
                ))}
              </div>

              <Image
                width={isMobile ? 140 : 170}
                image={{
                  src: p.image,
                }}
              />
            </div>
          ))}
        </StyledProdutosWrapper>
      </Container>
    </StyledHomeProdutos>
  );
};

export default HomeProdutos;
