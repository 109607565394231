import React from 'react';
import Container from '../../atoms/Container';
import Image from '../../atoms/Image';
import { PlataformaData } from './HomePlataforma.data';
import {
  StyledFerramentasContainer,
  StyledHomePlataforma,
  StyledPlataformaBg,
} from './HomePlataforma.styles';

const HomePlataforma = () => {
  return (
    <StyledHomePlataforma id="plataforma-section">
      <Container className="home-plataforma-container">
        <h2>
          Simples. <br />
          100% Digital.
        </h2>
        <Image
          wrapper={{
            className: 'circle',
          }}
          width={90}
          image={{
            src: 'assets/img/circulo-branco-sombra.png',
          }}
        />
        <h3 id="plataforma">Plataforma doHub</h3>
        <h4>
          Criada para integrar-se aos produtos de seguros e serviços com precisão, segurança e alta
          performance. Cuidamos da parte técnica e implantamos as ferramentas para sua empresa
          começar a vender.
        </h4>

        <StyledFerramentasContainer>
          {PlataformaData.map((p) => {
            return (
              <div className="item" key={`tool-${p.id}`}>
                <div className={`logo ${p.vertical ? ' vertical' : ''}`}>
                  <img src={p.icon} alt="" />
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: p.label,
                  }}
                />
              </div>
            );
          })}
        </StyledFerramentasContainer>
      </Container>
      <StyledPlataformaBg />
    </StyledHomePlataforma>
  );
};

export default HomePlataforma;
