import styled, { css } from 'styled-components';

export interface StyledContainerProps {
  full?: boolean;
}

export const StyledContainer = styled.div<StyledContainerProps>`
  box-sizing: border-box;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  ${(props) =>
    !props.full &&
    css`
      @media (min-width: 576px) {
        max-width: 540px;
      }
      @media (min-width: 768px) {
        max-width: 720px;
      }
      @media (min-width: 992px) {
        max-width: 960px;
      }
      @media (min-width: 1200px) {
        max-width: 1140px;
      }
      @media (min-width: 1400px) {
        max-width: 1320px;
      }
      height: 100%;
    `};
`;
