import styled, { css } from 'styled-components';

const bgHeight = 460;

const StyledSize = css`
  height: ${bgHeight}px;

  @media (min-width: 992px) {
    height: ${bgHeight - 60}px;
  }
`;

export const StyledHomeNumeros = styled.section`
  position: relative;
  z-index: 1;
  margin-top: 80px;
  margin-bottom: 36px;
  ${StyledSize}

  .circle {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  .home-numeros-container {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    > h2 {
      font-size: ${({ theme }) => `${theme.font.title}px`};
      line-height: ${({ theme }) => `${theme.font.title + 2}px`};
      color: #fff;
      font-weight: 500;
      margin: 32px 0;
      @media (min-width: 992px) {
        font-weight: bold;
        margin: 64px 0;
      }
    }
  }
`;

export const StyledNumerosBg = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 0;
  background-image: url(assets/img/bg_numeros.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0px;
  width: 100%;
  max-width: 2560px;
  transform: translate(-50%, 0);
  ${StyledSize}
`;

export const StyledNumerosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 300px;
  @media (min-width: 992px) {
    max-width: 100%;
  }

  .desk-only {
    display: none;
    @media (min-width: 992px) {
      display: flex;
    }
  }

  .mobile-only {
    display: flex;
    @media (min-width: 992px) {
      display: none;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    min-width: 140px;
    margin-bottom: 16px;

    h4 {
      text-transform: none;
      font-size: 40px;
      margin-bottom: 16px;

      b {
        font-size: 26px;
      }
    }

    p {
      text-transform: uppercase;
    }
  }
`;
