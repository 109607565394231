import React, { useEffect, useState } from 'react';
import { useLayout } from '../../../hooks/useLayout';
import Container from '../../atoms/Container';
import Image from '../../atoms/Image';
import { StyledHeader, StyledNavigation } from './Header.styles';

const Header: React.FC = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const { isMobile } = useLayout();

  useEffect(() => {
    if (openMenu) {
      const body = document.getElementsByTagName('body')[0];
      body.style.overflow = 'hidden';
    } else {
      const body = document.getElementsByTagName('body')[0];
      body.style.overflow = 'initial';
    }
  }, [openMenu]);

  return (
    <header id="header-dohub">
      <Container>
        <StyledHeader>
          <Image
            width={32}
            wrapper={{
              className: 'toggle-menu',
              style: {
                cursor: 'pointer',
              },
              onClick: () => {
                setOpenMenu(true);
              },
            }}
            image={{
              src: 'assets/img/menu.png',
              alt: '',
            }}
          />
          <Image
            width={180}
            image={{
              src: 'assets/img/logo_header.png',
              alt: '',
            }}
          />
          <StyledNavigation isMenu={isMobile} isVisible={openMenu}>
            <Image
              width={32}
              wrapper={{
                className: 'toggle-menu',
                style: {
                  cursor: 'pointer',
                },
                onClick: () => {
                  setOpenMenu(false);
                },
              }}
              image={{
                src: 'assets/img/menu.png',
                alt: '',
              }}
            />
            <ul>
              <li>
                <a
                  href="#"
                  onClick={() => {
                    setOpenMenu(false);
                  }}>
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#quem-somos"
                  onClick={() => {
                    setOpenMenu(false);
                  }}>
                  Quem somos
                </a>
              </li>
              <li>
                <a
                  href="#plataforma"
                  onClick={() => {
                    setOpenMenu(false);
                  }}>
                  Plataforma
                </a>
              </li>
              <li>
                <a
                  href="#servicos"
                  onClick={() => {
                    setOpenMenu(false);
                  }}>
                  Serviços Conectados
                </a>
              </li>
              <li>
                <a
                  href="#contato"
                  onClick={() => {
                    setOpenMenu(false);
                  }}>
                  Contato
                </a>
              </li>
            </ul>
          </StyledNavigation>
        </StyledHeader>
      </Container>
    </header>
  );
};

export default Header;
