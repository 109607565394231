export const ProdutosData = [
  {
    title: 'Seguros Financeiros',
    labels: ['- Perda e Roubo', '- Prestamista', '- Perda de Renda', '- Renda Protegida'],
    image: 'assets/img/card1-min.png',
    textColor: '#fff',
  },
  {
    title: 'Garantia Estendida',
    labels: ['Ideal para', 'empresas', 'varejistas'],
    image: 'assets/img/card3-min.png',
    textColor: '#fff',
  },
  {
    title: 'Auto Simplificado',
    labels: ['- Seguro Auto', '- Rastreador'],
    image: 'assets/img/card5-min.png',
    textColor: '#000',
  },
  {
    title: 'Coberturas de Saúde',
    labels: ['- Odontológico', '- Vale Saúde', '- Medicamentos', '- Farmácia'],
    image: 'assets/img/card7-min.png',
    textColor: '#000',
  },
  {
    title: 'Assistências e Capitalização',
    labels: ['- Auto/Moto', '- Funeral', '- Residencial', '- Sorteios', '- Monito. Digital'],
    image: 'assets/img/card8-min.png',
    textColor: '#000',
  },
  {
    title: 'Seguro Viagem',
    labels: ['Diversas opções,', 'incluindo', 'coberturas para', 'o Covid-19'],
    image: 'assets/img/card6-min.png',
    textColor: '#000',
  },
  {
    title: 'Seguro por Adesão',
    labels: ['Diversas opções', 'de seguros por', 'adesão direta.'],
    image: 'assets/img/card4-min.png',
    textColor: '#fff',
  },
  {
    title: 'Massificados',
    labels: [
      '- Acidentes Pessoais',
      '- RF Celular',
      '- Bolsa Protegida',
      '- Residencial',
      '- Vida Global',
      '- Vida Individual',
    ],
    image: 'assets/img/card2-min.png',
    textColor: '#fff',
  },
].map((p, i) => ({ ...p, id: i, labels: p.labels.map((l, li) => ({ text: l, id: li })) }));
